import PhotoGallery from '../components/PhotoGallery'

const images = [
  { src: 'gallery/Scan113919.jpg', alt: 'Scan113919' },
  { src: 'gallery/Scan113931.jpg', alt: 'Scan113931' },
  { src: 'gallery/Scan114496.jpg', alt: 'Scan114496' },
  { src: 'gallery/Scan114482.jpg', alt: 'Scan114482' },
  { src: 'gallery/Scan113924.jpg', alt: 'Scan113924' },
  { src: 'gallery/Scan113930.jpg', alt: 'Scan113930' },
  { src: 'gallery/Scan113926.jpg', alt: 'Scan113926' },
  { src: 'gallery/Scan113932.jpg', alt: 'Scan113932' },
  { src: 'gallery/Scan114481.jpg', alt: 'Scan114481' },
  { src: 'gallery/Scan114494.jpg', alt: 'Scan114494' },
  { src: 'gallery/Scan113927.jpg', alt: 'Scan113927' },
  { src: 'gallery/Scan113923.jpg', alt: 'Scan113923' },
  { src: 'gallery/Scan113934.jpg', alt: 'Scan113934' },
  { src: 'gallery/Scan113920.jpg', alt: 'Scan113920' },
  { src: 'gallery/Scan114486.jpg', alt: 'Scan114486' },
  { src: 'gallery/Scan113909.jpg', alt: 'Scan113909' },
  { src: 'gallery/Scan113985.jpg', alt: 'Scan113985' },
  { src: 'gallery/Scan113984.jpg', alt: 'Scan113984' },
  { src: 'gallery/Scan113953.jpg', alt: 'Scan113953' },
  { src: 'gallery/Scan113979.jpg', alt: 'Scan113979' },
  { src: 'gallery/Scan113986.jpg', alt: 'Scan113986' },
  { src: 'gallery/Scan113978.jpg', alt: 'Scan113978' },
  { src: 'gallery/Scan113940.jpg', alt: 'Scan113940' },
  { src: 'gallery/Scan113968.jpg', alt: 'Scan113968' },
  { src: 'gallery/Scan113982.jpg', alt: 'Scan113982' },
  { src: 'gallery/Scan113969.jpg', alt: 'Scan113969' },
  { src: 'gallery/Scan113981.jpg', alt: 'Scan113981' },
  { src: 'gallery/Scan113973.jpg', alt: 'Scan113973' },
  { src: 'gallery/Scan113972.jpg', alt: 'Scan113972' },
  { src: 'gallery/Scan113958.jpg', alt: 'Scan113958' },
  { src: 'gallery/Scan113971.jpg', alt: 'Scan113971' },
  { src: 'gallery/Scan113961.jpg', alt: 'Scan113961' },
  { src: 'gallery/Scan113976.jpg', alt: 'Scan113976' },
  { src: 'gallery/Scan113962.jpg', alt: 'Scan113962' },
  { src: 'gallery/Scan113988.jpg', alt: 'Scan113988' },
  { src: 'gallery/Scan113963.jpg', alt: 'Scan113963' },
  { src: 'gallery/Scan114500.jpg', alt: 'Scan114500' },
  { src: 'gallery/Scan113938.jpg', alt: 'Scan113938' },
  { src: 'gallery/Scan113910.jpg', alt: 'Scan113910' },
  { src: 'gallery/Scan113911.jpg', alt: 'Scan113911' },
  { src: 'gallery/Scan113939.jpg', alt: 'Scan113939' },
  { src: 'gallery/Scan114501.jpg', alt: 'Scan114501' },
  { src: 'gallery/Scan114503.jpg', alt: 'Scan114503' },
  { src: 'gallery/Scan114476.jpg', alt: 'Scan114476' },
  { src: 'gallery/Scan113912.jpg', alt: 'Scan113912' },
  { src: 'gallery/Scan114502.jpg', alt: 'Scan114502' },
  { src: 'gallery/Scan114506.jpg', alt: 'Scan114506' },
  { src: 'gallery/Scan113916.jpg', alt: 'Scan113916' },
  { src: 'gallery/Scan113917.jpg', alt: 'Scan113917' },
  { src: 'gallery/Scan113915.jpg', alt: 'Scan113915' },
  { src: 'gallery/Scan113929.jpg', alt: 'Scan113929' },
  { src: 'gallery/Scan114471.jpg', alt: 'Scan114471' },
  { src: 'gallery/Scan113928.jpg', alt: 'Scan113928' },
  { src: 'gallery/Scan114504.jpg', alt: 'Scan114504' },
]
export default function Gallery() {
  return <PhotoGallery images={images} />
}
